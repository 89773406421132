.ai-talking-bg-img {
    position: relative;
    background-image: url('../images/back_blue.jpg') !important;
    background-repeat: no-repeat !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-size: cover !important;

    // min-height: 100vh;
    .ant-layout-header {
        background-color: #ffffff !important;
        padding-left: 20px;
        padding-right: 20px;
        // height: 60px;

        .anticon {
            svg {
                color: #1561a0;
            }
        }

        .ant-menu {
            .ant-menu-submenu {
                .ant-menu-title-content {
                    span {
                        color: #1561a0 !important;
                    }
                }
            }
        }
    }

    .ai-tutor-layout {
        &.ant-layout-content {
            .add-header {
                background-color: rgba(51, 153, 221, 1);
                padding: 14px 20px;

                h2 {
                    color: #ffffff;
                    text-transform: lowercase;
                }
            }

            .ant-form {
                // padding: 30px 60px 45px 80px;
                padding: 0 40px 25px;

                &::after {
                    bottom: 45px;
                    left: 40px;
                    right: 40px;
                    display: none;
                }

                .middle-row {
                    position: relative;
                    z-index: 2;
                    padding: 30px 40px;
                    background: rgba(255, 255, 255, 80%);
                    border-bottom-left-radius: 40px;
                    border-bottom-right-radius: 40px;
                    // height: calc(77vh - 10px);

                    .left-part {
                        .middle-input {
                            .ant-form-item {
                                .ant-row {
                                    margin-bottom: 30px;

                                    .ant-col {
                                        &.ant-form-item-label {
                                            padding-bottom: 0;
                                            min-width: 100px;

                                            label {
                                                color: rgba(0, 103, 153, 1);
                                                font-size: 22px;
                                                font-weight: 700;
                                                line-height: 26.63px;
                                            }
                                        }

                                        &.ant-form-item-control {
                                            .ant-input {
                                                padding: 9px 14px;
                                                border-radius: 12px;
                                                border: none;
                                                background: rgba(80, 196, 248, 10%);

                                                &.msg-input {
                                                    background: rgba(255, 234, 182, 20%);
                                                }
                                            }

                                            textarea {
                                                min-height: 75px;
                                            }

                                            .msg-clear-btn {
                                                font-size: 13.65px;
                                                font-weight: 700;
                                                line-height: 16.51px;
                                                margin: -15px 0 15px;

                                            }
                                        }




                                    }
                                }
                            }

                            &.audio-input {
                                .setting-btns.setting-audio {
                                    margin-top: 65px;

                                    .setting-action-wrap {
                                        align-items: flex-start;

                                        .guide-btn {
                                            img {
                                                height: 32px;
                                                width: 32px;
                                            }

                                            p {
                                                font-size: 12.67px;
                                                font-weight: 700;
                                                line-height: 15.33px;
                                                color: rgba(0, 103, 153, 1);
                                                margin: 5px 0 0;
                                            }
                                        }

                                        .left-btn {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            position: relative;
                                            gap: 23px;

                                            &.action-btn-wrap {
                                                background: rgba(169, 223, 251, 1);
                                                height: 47px;
                                                border-radius: 12px;
                                                margin: 0;

                                                &::after {
                                                    display: none;
                                                }

                                                .action_item {
                                                    margin-top: -30px;

                                                    .img_wrap {
                                                        padding: 6px;
                                                        background-color: #ffffff;
                                                        border-radius: 50%;
                                                    }

                                                    &.mice_item {
                                                        margin-top: -50px;
                                                    }
                                                }
                                            }

                                            .action_item {
                                                min-width: 63px;
                                                justify-content: center;
                                                align-items: center;
                                                display: flex;
                                                flex-direction: column;
                                                gap: 2px;
                                                z-index: 9;

                                                .hover-icon {
                                                    &:hover {
                                                        filter: none;
                                                    }
                                                }

                                                h6 {
                                                    color: rgba(51, 153, 221, 1);
                                                    font-size: 9.88px;
                                                    font-weight: 700;
                                                    line-height: 11.95px;
                                                    margin: 2px 0 0;
                                                }
                                            }

                                            &.leftt {
                                                margin: 10px auto 0px;
                                            }

                                            // .hover-icon {
                                            //     &:hover {
                                            //         filter: hue-rotate(45deg);
                                            //     }
                                            // }
                                        }

                                        .disabled-btn {
                                            cursor: not-allowed;
                                            opacity: 0.5
                                        }

                                        .enabled-btn {
                                            cursor: pointer;
                                        }
                                    }


                                }
                            }


                        }
                    }


                    .right-part {
                        .dialog-wrap {
                            width: 100%;

                            .clear-timer {
                                position: relative;

                                .clear-timer-box {
                                    display: flex;
                                    justify-content: end;
                                    gap: 15px;
                                    align-items: baseline;
                                    position: absolute;
                                    right: 50px;
                                    top: 0;
                                    cursor: pointer;

                                    .item {
                                        p {
                                            color: rgba(253, 103, 2, 1);
                                            font-size: 22px;
                                            font-weight: 700;
                                            margin-bottom: 0;
                                            display: inline-flex;
                                            align-items: center;
                                            gap: 5px;

                                            span {
                                                border: 1.5px solid rgb(255, 146, 43);
                                                background-color: rgba(255, 146, 43, .2);
                                                color: rgb(255, 146, 43);
                                                font-size: 20px;
                                                line-height: 24px;
                                                font-weight: 700;
                                                position: relative;
                                                top: 0;
                                                padding: 3px 5px;
                                                border-radius: 4px;
                                            }
                                        }

                                        .clear-btn {
                                            font-size: 22px;
                                            text-transform: capitalize;
                                            font-weight: 700;
                                            color: rgba(0, 129, 214, 1);

                                            img {
                                                width: 33px;
                                                height: 35px;
                                            }
                                        }
                                    }
                                }
                            }

                            .form_item_wrap {
                                display: flex;
                                gap: 10px 22px;

                                .ant-form-item {
                                    width: 100%;

                                    .ant-row {
                                        margin-bottom: 0;

                                        .ant-col {
                                            &.ant-form-item-label {
                                                padding-bottom: 0;

                                                label {
                                                    font-size: 22px;
                                                    font-weight: 700;
                                                    line-height: 26.63px;
                                                    color: rgba(0, 103, 153, 1);
                                                }
                                            }

                                            .ant-form-item-control-input-content {

                                                .message-box {
                                                    width: 100%;
                                                    padding: 25px 22px;
                                                    border-radius: 15px;
                                                    background: rgba(80, 196, 248, 10%);
                                                    box-shadow: 7px 7px 10px 0px rgb(0 0 0 / 7%);
                                                    border-color: transparent;
                                                    // height: calc(100vh - 350px);

                                                    &::-webkit-scrollbar {
                                                        width: 7px;
                                                    }


                                                    &::-webkit-scrollbar-thumb {
                                                        background: rgba(0, 129, 214, 30%);
                                                        border-radius: 10px;
                                                    }

                                                    span {
                                                        position: relative;
                                                        display: block;
                            
                                                        &::first-letter {
                                                            text-transform: capitalize;
                                                        }
                                                    }

                                                    .chat-detail {
                                                        .msg_content_wrapper {
                                                            flex-direction: column;

                                                            .msg_date {
                                                                display: none;
                                                            }

                                                            .msg_block {
                                                                padding: 11px 15px;
                                                                margin: 2px 3px 22px;

                                                                span {
                                                                    font-size: 14.85px;
                                                                    font-weight: 700;
                                                                    line-height: 17.97px;
                                                                    color: rgba(0, 0, 0, 1);
                                                                    display: block;

                                                                    &::first-letter {
                                                                        text-transform: capitalize;
                                                                    }
                                                                }
                                                            }
                                                        }


                                                        &.ai-chat-detail {
                                                            .ai-detail {
                                                                font-size: 18px;
                                                                font-weight: 600;
                                                                color: #0082d8;
                                                                display: none;
                                                            }

                                                            .msg_content_wrapper {
                                                                align-items: flex-start;

                                                                .msg_block {
                                                                    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.05);
                                                                    background: rgba(255, 255, 255, 1);
                                                                    border-radius: 4px 35px 35px 35px;

                                                                    span {
                                                                        color: rgba(0, 0, 0, 1);
                                                                    }
                                                                }
                                                            }

                                                        }

                                                        &.user-chat-detail {

                                                            .user-detail {
                                                                text-align: end;
                                                                font-size: 18px;
                                                                font-weight: 600;
                                                                color: #9383e8;
                                                                display: none;
                                                            }

                                                            .msg_block {
                                                                box-shadow: 5px 5px 6px 0px rgba(0, 0, 0, 0.05);
                                                                background: rgba(107, 172, 221, 1);
                                                                border-radius: 35px 4px 35px 35px;

                                                                span {
                                                                    color: rgba(255, 255, 255, 1);
                                                                }
                                                            }
                                                        }
                                                    }




                                                }
                                            }
                                        }
                                    }

                                }

                                .setting-wrap {
                                    min-width: 30px;
                                    margin-top: auto;
                                    margin-bottom: 20px;
                                    width: auto;

                                    .setting-wrap-inner {
                                        align-items: center;
                                        gap: 20px;

                                        .setting_item {
                                            display: flex;
                                            flex-direction: column;
                                            gap: 5px;
                                            align-items: center;

                                            img {
                                                max-width: 100%;
                                                height: unset;
                                                width: unset;
                                            }

                                            .review-save-btn {
                                                padding: 0;
                                                border: none;
                                                height: auto;
                                                display: block;
                                            }

                                            h6 {
                                                color: rgba(255, 103, 0, 1);
                                                font-size: 12.87px;
                                                font-weight: 700;
                                                line-height: 15.57px;
                                                margin: 0;
                                            }
                                        }

                                    }
                                }
                            }


                        }




                    }


                }

                .copyright {
                    color: rgba(255, 255, 255, 1);
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 21.78px;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 10px;
                }
            }
        }
    }
}



.ant-layout-has-sider {
    .ai-tutor-layout.ant-layout-content {
        .ant-form {
            .add-header-field {
                .cust-one {
                    width: 74%;

                    // .ant-form-item.select-form-control {
                    //     .ant-select.ant-select-in-form-item {
                    //         width: 275%;
                    //     }
                    // }
                }
            }

            .middle-row {
                .left-part {
                    width: 65%;
                }

                .right-part {
                    width: 35%;
                }
            }
        }
    }
}

.ai-speaking-main {
    .ai-tutor-layout.ant-layout-content {
        .add-header {
            background-color: #b2d3e7;
        }

        .ant-form {
            background-image: url('../images/talking_tutor_back.png') !important;

            .ant-form-item {
                .ant-row.ant-form-item-row {
                    .ant-input {
                        border-color: #b2d3e7;
                    }

                    .ant-form-item-label {
                        label {
                            color: #10337b;
                        }
                    }
                }
            }

            .middle-row {
                .right-part {
                    span {
                        color: #10337b;
                    }

                    .form-btn {
                        .right-btn {
                            button {
                                span {
                                    position: relative;
                                    border: 2px solid #10337b;
                                    padding: 3px 10px;
                                    border-radius: 100px;
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

.ai-tutor-layout {
    margin: 0 !important;

    &.ant-layout-content {
        position: relative;
        overflow-x: hidden;

        img.header-btn {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .add-header {
            display: flex;
            justify-content: space-between;
            background-color: rgba(82, 152, 216, 1);
            padding: 17px 20px;
            position: relative;
            z-index: 2;

            h2 {
                font-size: 26px;
                text-transform: capitalize;
                font-weight: 600;
                color: #231814;
                margin-bottom: 0;
                line-height: normal;
            }

            .right-section {
                display: flex;
                align-items: center;
                gap: 20px;

                .upload-btn {
                    position: relative;

                    img {
                        cursor: pointer;
                        position: relative;
                    }

                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        width: auto !important;
                        margin: 0 !important;
                        cursor: pointer;
                    }
                }

                button {
                    background: transparent;
                    border: unset;
                    padding: 0;
                    box-shadow: unset;
                }
            }
        }

        .ant-form {
            position: relative;
            padding: 40px 70px;
            //background-image: url('../images/back_yellow.png');
            background-repeat: no-repeat;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-size: cover;
            min-height: calc(100vh - 130px);
            // min-height: 100vh;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 30px;
                left: 20px;
                right: 20px;
                background-color: rgb(255 255 255 / 90%);
                border-bottom-left-radius: 40px;
                border-bottom-right-radius: 40px;
                z-index: 1;
                min-height: calc(100vh - 190px);
                // min-height: 98vh;
            }

            .ant-form-item-row {
                margin-bottom: 20px;
                flex-direction: row;
                align-items: center;
            }

            .add-header-field {
                display: flex;
                justify-content: space-between;
                gap: 10px 20px;

                &.dynamic-content {
                    .cust-one {
                        width: 78%;

                        .ant-form-item.select-form-control {
                            width: 55%;

                            // .ant-select.ant-select-in-form-item {
                            //     width: 195%;
                            // }
                        }
                    }

                    .cust-two {
                        width: 30%;
                    }
                }


            }

            .main-row {
                display: flex;
                width: 100%;
                gap: 20px;
                position: relative;
                z-index: 2;

                &.cust-row {
                    .ant-form-item {
                        .ant-form-item-row {
                            .ant-form-item-control {
                                width: 55%;
                                max-width: 55%;
                            }
                        }
                    }
                }

                .ant-form-item {
                    width: 100%;

                    .ant-form-item-row {
                        flex-direction: unset;
                        gap: 20px;
                        align-items: center;
                        flex-wrap: nowrap;

                        .ant-form-item-control {
                            width: 70%;
                            max-width: 70%;

                            .ant-form-item-control-input {
                                .ant-form-item-control-input-content {
                                    .ant-select {
                                        .ant-select-selector {
                                            padding: 7px 11px;
                                            border-radius: 13px;
                                            height: 49px;
                                            border-color: #D3E6EF;

                                            .ant-select-selection-item {
                                                color: #1f317d;
                                                font-size: 19px;
                                                font-weight: 600;
                                            }
                                        }

                                        span.ant-select-arrow {
                                            width: 49px;
                                            height: 49px;
                                            background: #B5D2E8;
                                            text-align: center;
                                            line-height: 49px;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 10px;
                                            margin-top: -25px;
                                            right: 0;
                                            border: 2px solid #92B5D3;

                                            .anticon {
                                                svg {
                                                    width: 30px;
                                                    height: 20px;
                                                    fill: #10337b;
                                                }
                                            }
                                        }

                                        &.ant-select-single.ant-select-open {
                                            .ant-select-selection-item {
                                                color: #1f317d;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.select-form-control {
                        .ant-form-item-row {
                            .ant-form-item-control {
                                width: 49%;
                                max-width: 49%;
                            }
                        }
                    }
                }
            }

            .middle-row {
                display: flex;
                gap: 20px;
                position: relative;
                z-index: 2;

                .left-part {
                    width: 50%;
                    display: flex;
                    gap: 20px;

                    .content-list {
                        width: 26%;
                        background: #5E87A5;
                        text-align: center;
                        padding: 10px 0px;
                        border-radius: 10px;
                        max-height: 437px;
                        position: relative;
                        overflow: hidden;

                        h2 {
                            font-size: 21px;
                            margin-bottom: 5px;
                            text-transform: capitalize;
                            font-weight: 600;
                            color: #fff;
                        }

                        .list-items {
                            border-radius: 10px;
                            // height: 100%;
                            height: 85%;
                            background: #E1EEF4;
                            border: 1px solid #CBE2EF;
                            overflow-y: auto;

                            span {
                                padding: 10px;
                                border-bottom: 1px solid #ccc;
                                display: block;
                                font-size: 15px;
                                display: flex;

                                p {
                                    width: 80%;
                                }

                                &.active-span {
                                    background-color: #1f317d;
                                    color: #fff;
                                    opacity: .9;
                                }
                            }
                        }

                        button {
                            &.add-more-btn {
                                position: absolute;
                                bottom: 0px;
                                left: 0;
                                width: 100%;
                                border: unset;
                                background: #429ad5;
                                padding: 6px 0px;
                                border-radius: 0px 0px 10px 10px;
                                text-transform: capitalize;
                                font-size: 15px;
                                font-weight: 600;
                                color: #fff;
                            }

                            &.remove-btn {
                                padding: 0;
                                background: transparent;
                                color: #fff;
                                border: unset;
                                position: relative;
                                width: 20%;

                                span {
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: red;
                                    // position: absolute;
                                    // top: -8px;
                                    // right: -75px;
                                    border-bottom: unset;
                                    padding: 0 0 0 18px;

                                }
                            }
                        }
                    }

                    .middle-input {
                        width: 65%;

                        &.audio-input {
                            width: 100%;

                            .msg-clear-btn {
                                text-align: end;
                                margin: 0 0 8px;

                                img {
                                    height: 28px;
                                }
                            }

                            .setting-btns.setting-audio {
                                .setting-action-wrap {
                                    display: flex;
                                    align-items: center;

                                    .left-btn.action-btn-wrap {
                                        margin: 0px 115px;
                                    }

                                    .guide-btn {
                                        text-align: center;
                                        color: #FF6700;
                                        cursor: pointer;

                                        img {
                                            height: 35px;
                                        }
                                    }
                                }

                                .left-btn {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 20px;
                                    margin: 10px 64px 0px 113px;


                                    &.leftt {
                                        margin: 10px auto 0px;
                                    }

                                    @media only screen and (max-width: 1300px) {
                                        margin: 10px 64px 0px 104px;
                                    }

                                    .hover-icon {
                                        &:hover {
                                            filter: hue-rotate(45deg);
                                        }
                                    }

                                    &.action-btn-wrap {
                                        display: flex;
                                        justify-content: center;
                                        margin: 10px 65px 0 116px;
                                        position: relative;
                                        gap: 26px;

                                        &:after {
                                            position: absolute;
                                            top: 7px;
                                            content: "";
                                            background-color: #FFEABC;
                                            height: 38px;
                                            width: 500px;
                                            border-radius: 8px;
                                            z-index: -1
                                        }

                                        div {
                                            p {
                                                margin: 10px 0 0;
                                                color: #FF6700;
                                                text-align: center;

                                                &:hover {
                                                    color: #0082D8;
                                                }
                                            }
                                        }

                                        @media only screen and (max-width: 1300px) {
                                            margin: 10px 48px 0 92px;
                                        }
                                    }
                                }

                                .disabled-btn {
                                    cursor: not-allowed;
                                    opacity: 0.5
                                }

                                .enabled-btn {
                                    cursor: pointer;
                                }
                            }
                        }

                        .promot-tabs {
                            position: relative;

                            .ant-tabs-nav {
                                margin-bottom: 0;
                                position: absolute;
                                top: -41px;
                                right: 7px;

                                .ant-tabs-tab {
                                    +.ant-tabs-tab {
                                        margin-left: 8px;
                                    }
                                }

                                .ant-tabs-nav-wrap {
                                    .ant-tabs-nav-list {
                                        .ant-tabs-tab {
                                            border-radius: 12px 12px 0 0;
                                            background: #5E87A5;
                                            padding: 6px 19px;
                                            font-size: 18px;
                                            color: #fff;
                                            border: 1px solid #5E87A5;
                                            border-bottom: unset;
                                            font-weight: 600;

                                            &.ant-tabs-tab-active {
                                                background-color: #E1EEF4;

                                                .ant-tabs-tab-btn {
                                                    color: #1f317d;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .setting-btns {
                            .left-btn {
                                display: flex;
                                justify-content: space-between;
                            }
                        }

                        .action-img-btn-wrap {
                            display: flex;
                            gap: 20px;
                            justify-content: center;
                            padding-bottom: 20px;
                            position: relative;

                            &:after {
                                position: absolute;
                                content: "";
                                top: 7%;
                                left: 0;
                                background-color: #FFEABC;
                                height: 38px;
                                width: 100%;
                                border-radius: 8px;
                                z-index: -1;
                            }

                            img {
                                height: 50px;
                                cursor: pointer;
                            }

                            .disabled-btn {
                                cursor: not-allowed;
                                opacity: 0.5
                            }
                        }
                    }


                }

                .right-part {
                    width: 50%;
                    position: relative;

                    &.audio-right-part {
                        display: flex;
                        gap: 20px;
                        // justify-content: end;
                    }

                    .dialog-wrap {
                        width: 92%;

                        .clear-timer {
                            position: relative;

                            .clear-timer-box {
                                display: flex;
                                justify-content: end;
                                gap: 20px;
                                align-items: baseline;
                                position: absolute;
                                right: 0;
                                top: 0;
                                z-index: 9999;
                                cursor: pointer;

                                .item {
                                    p {
                                        color: #FF6700;
                                        font-size: 22px;
                                        font-weight: 400;
                                        margin-bottom: 0;

                                        span {
                                            border: 1px solid rgb(255, 146, 43);
                                            background-color: rgba(255, 146, 43, .2);
                                            color: rgb(255, 146, 43);
                                            font-size: 14px;
                                            position: relative;
                                            top: 0;
                                            padding: 4px;
                                            border-radius: 4px;
                                        }
                                    }

                                    .clear-btn {
                                        font-size: 22px;
                                        text-transform: capitalize;
                                        font-weight: 400;
                                        color: #0082D8;
                                    }
                                }
                            }
                        }
                    }

                    .setting-wrap {
                        width: 9%;
                        margin-top: auto;
                        margin-bottom: 4%;

                        .setting-wrap-inner {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;

                            img {
                                height: 40px;
                                width: 40px;
                            }
                        }
                    }

                    .message-box {
                        padding: 12px 11px;
                        border-radius: 13px;
                        border: 1px solid #FFEABF;
                        background-color: #fff;

                        span {
                            position: relative;
                            display: block;
                            
                            &::first-letter {
                                text-transform: capitalize;
                            }
                        }

                        .user-detail {
                            text-align: end;
                            font-size: 18px;
                            font-weight: 600;
                            color: #9383e8;
                        }

                        .ai-detail {
                            font-size: 18px;
                            font-weight: 600;
                            color: #0082d8;
                        }
                    }

                    // .ai-chat-history {
                    //     .user-detail {
                    //         text-align: end;
                    //     }
                    // }

                    .clear-wrap {
                        position: absolute;
                        // top: -37px;
                        top: 2px;
                        right: 0;
                        font-size: 18px;
                        text-transform: capitalize;
                        font-weight: 600;
                        color: #3881D3;
                        z-index: 100;

                        img {
                            margin-left: 10px;
                            cursor: pointer;
                            height: 25px;
                        }
                    }

                    span {
                        position: absolute;
                        // top: -10px;
                        right: 0;
                        font-size: 20px;
                        //  text-transform: capitalize;
                        // text-transform: capitalize;
                        font-weight: 600;
                        color: #702F00;

                        img {
                            margin-left: 10px;
                            cursor: pointer;
                        }
                    }

                    .upload-lable {
                        font-size: 20px;
                        font-weight: 600;
                        color: #1f317d;
                        line-height: 40px;
                    }

                    .upload-wrap {
                        span {
                            position: relative;
                            top: unset;
                            right: unset;
                            font-size: 14px;

                            &.ant-spin-dot {
                                font-size: 30px;
                            }
                        }

                        .ant-form-item-control-input-content {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .upload-file-list {
                            text-transform: capitalize;
                            font-weight: 600;
                            color: #3881D3;
                            padding: 10px;
                            background: #fff;
                            border: 1px solid #D3E6EF;
                            border-radius: 8px;
                            margin-bottom: 20px;
                            height: 112px;
                            overflow-y: auto;
                        }

                        .upload-wrappper {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 10px;
                        }
                    }
                }
            }

            .ant-form-item {
                .ant-row.ant-form-item-row {
                    .ant-form-item-label {
                        label {
                            font-size: 20px;
                            line-height: 24px;
                            font-weight: 400;
                            color: #702F00;
                        }
                    }

                    .ant-input {
                        padding: 12px 11px;
                        border-radius: 13px;
                        border-color: #FFEABF;

                        &.msg-input {
                            font-size: 20px;
                            font-weight: 400;
                            color: #0082D8;

                            &::first-letter {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }

            .form-btn {
                display: flex;
                align-items: center;
                position: relative;
                z-index: 2;
                margin-top: auto;
                // height: 14%;

                .setting-btns {
                    width: 43%;
                    display: flex;
                    justify-content: flex-end;

                    &.setting-audio {
                        justify-content: flex-start;

                    }

                    .left-btn {
                        width: 75%;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .right-btn {
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    justify-content: flex-end;

                    button {
                        padding: 0;
                        border: 0;

                        &.save-btn {
                            font-size: 18px;
                            padding: 2px 20px;
                            border-radius: 12px;
                            color: rgb(255, 146, 43);
                            font-weight: 600;
                            border: 1px solid;
                        }

                        &.review-save-btn {
                            background-color: transparent;

                            // margin-right: 88px;
                            span {
                                color: rgb(255, 146, 43);
                                font-size: 18px;
                                font-weight: 600;
                                top: 2px;
                                margin-right: 50px;
                            }
                        }
                    }
                }

                // .disable-form-btn {
                //     opacity: 0.5;
                //     cursor: not-allowed;
                // }
            }

            .ant-form-item-explain-error {
                padding-left: 10px;
                margin-top: 2px;
                font-size: 15px;
            }
        }
    }


    //sidebar design strat
    .ant-layout-sider {
        max-width: 350px !important;
        min-width: 350px !important;
        padding: 15px;

        .sidebar-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0;

            .setting-icon-wrap {
                span {
                    color: #fff;
                    margin: 0 12px;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }

        .user-guid-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            background-color: #5988a4;
            border-radius: 20px;
            padding: 8px;
            margin: 40px 0;

            h2 {
                margin-bottom: 0;
                color: #fff;
                font-weight: 600;
            }
        }

        .sidebar-inner {
            background-color: #dce7f0;
            padding: 30px 25px;
            height: calc(100% - 65px);

            .sidebar-inner-box {
                background-color: #fff;
                border-radius: 12px;
                padding: 15px;

                .detail-box {
                    display: flex;
                    gap: 20px;
                    align-items: center;

                    h2 {
                        margin-bottom: 0;
                        font-weight: 600;


                        &:last-child {
                            color: #00639e;
                        }
                    }
                }
            }

            .rank__wrap {
                .sidebar-select {
                    width: 100%;
                    padding: 12px 6px;
                    border-radius: 12px;
                    position: relative;

                    &:after {
                        position: absolute;
                        content: "";
                        width: 36px;
                        height: 36px;
                        background: #B5D2E8;
                        text-align: center;
                        line-height: 49px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        margin-top: -18px;
                        right: 0;
                        border: 2px solid #92B5D3;
                    }
                }

                .ant-row {
                    display: block;
                    margin-bottom: 30px;

                    label {
                        font-size: 16px;
                        font-weight: 600;
                        color: #00639e;
                    }

                    .ant-form-item-control-input {
                        .ant-select-selector {
                            padding: 4px 11px 7PX;
                            border-radius: 13px;
                            height: 38px;
                            border-color: #D3E6EF;
                        }

                        .ant-select-arrow {
                            user-select: none;
                            width: 36px;
                            height: 36px;
                            background: #B5D2E8;
                            text-align: center;
                            line-height: 49px;
                            align-items: center;
                            justify-content: center;
                            border-radius: 10px;
                            margin-top: -18px;
                            right: 0;
                            border: 2px solid #92B5D3;
                        }
                    }

                    .attendance-wrap {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 2px solid #92B5D3;
                        background: #fff;
                        padding: 4px;
                        border-radius: 8px;

                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .sidebar-form {
                position: relative;
                padding: inherit;
                background-image: unset;
                min-height: inherit;

                &::after {
                    content: unset;
                }
            }
        }
    }

    // sidebar design end
}

.ant-layout.ant-layout-has-sider>.ant-layout-content {
    width: 100%;
}

.ant-layout.ant-layout-has-sider.main-layout {
    flex-direction: column;
}

.progress-text {
    color: #ff922b;
    font-size: 16px;
}

.progress-bar {
    .ant-progress-bg {
        background-color: #ff922b;
    }

    .ant-progress-outer {
        display: flex;
        align-items: center;

        .ant-progress-inner {
            width: 94%;
        }
    }

}


.sidebar-overlay {
    position: absolute;
    inset: 0;
    z-index: 1000;
    background: rgb(0 0 0 / 45%);
    pointer-events: auto;
    height: 100vh;
}

.disable-upload-list {
    .ant-upload-list {
        display: none;
    }

}

.disabled-upload-btn {
    opacity: 0.5;
    cursor: not-allowed;
}

.delet-btn {
    border: 1px solid #ff9933;
    color: #ff9933;

    &:hover {
        opacity: .5;
        border: 1px solid #ff9933;
        color: #ff9933;
    }
}

.instruction-modal {
    top: 32% !important;
    width: 800px !important;

    &.password-modal {
        width: 500px !important;
    }

    .ant-modal-header {
        padding: 16px 24px 0 !important;
        border-bottom: unset !important;
    }

    .ant-modal-title {
        color: #429ad5;
        font-weight: 700;
        font-size: 18px;
    }

    .ant-modal-body {
        padding: 0px 24px 24px;
    }

    textarea.ant-input {
        border-color: #b2d3e7 !important;
    }

    .instruction-btn-wrap {
        display: flex;
        align-items: baseline;
        padding: 20px 0 0;

        // justify-content: end;

        button {
            border: unset;
            border-color: unset;
        }
    }

    .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
        justify-content: end !important;
        margin-bottom: 0;
    }

    .ant-tabs>.ant-tabs-nav,
    .ant-tabs>div>.ant-tabs-nav {
        margin-bottom: 0;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #1f317d;
    }

    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
        background-color: #E1EEF4 !important;
        border-radius: 12px 12px 0 0;
        padding: 6px 19px;
        font-size: 18px;
        color: #1f317d !important;
        border: 1px solid #5E87A5 !important;
        border-bottom: unset !important;
        font-weight: 600;
    }

    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
        margin-left: 8px;
        border-radius: 12px 12px 0 0;
        background-color: #5E87A5;
        padding: 6px 19px;
        font-size: 18px;
        color: #fff;
        border: 1px solid #5E87A5;
        border-bottom: unset;
        font-weight: 600;
    }

    .ant-modal-content {
        .ant-modal-close {
            top: -12px;
            right: -12px;
        }
    }

}

.content-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

div>div>.ant-select-dropdown {
    z-index: 99999;
}

.guide-img-modal {
    top: 75px;

    .ant-modal-footer,
    .ant-modal-close {
        display: none;
    }

    .modal-img {
        width: 100%;
        height: auto;
    }
}

.asked-modal {

    top: 55%;

    h1 {
        color: #6daf4b;
        font-weight: 700;
    }

    .ant-modal-content {
        background-image: url('../images/imgpsh_fullsize_anim.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        min-height: 248px;
        background-color: transparent;

        .ant-modal-body {
            padding: 44px 40px;

            label {
                color: #555e4d;
                font-size: 18px;
                font-weight: 700;
                min-width: 140px;


            }

            .title-wrap {
                display: flex;
                gap: 24px;

                label {
                    color: #6daf4b;
                }

                .btn-wrap {
                    button {
                        background-color: #6daf4b;
                        color: #fff;
                        border-radius: 12px;
                        font-size: 16px;
                        font-weight: 700;
                        padding: 6px 32px;
                        cursor: pointer;
                        outline: none;
                        border: 1px solid #6daf4b;

                        &:hover {
                            background-color: #dfdfdf;
                            color: #6daf4b;
                            border: 1px solid #dfdfdf;

                        }
                    }

                    .audio-record {
                        height: 45px !important;
                    }
                }
            }

        }
    }

    .ant-modal-footer {
        display: none;
    }

    .ant-modal-close {
        top: 16px;
        right: 16px;

        svg {
            color: #6daf4b;
            font-weight: 900;
            font-size: 20px;
        }
    }


}

.restart-task-modal {
    .ant-modal-content {
        .ant-modal-body {
            h3 {
                margin-top: 18px;
                font-size: 24px;
                font-weight: 600;
                color: rgb(47, 85, 151);
                text-align: center;
                line-height: 2;

            }

            .modal-footer {
                display: flex;
                justify-content: end;
                gap: 12px;
                padding: 10px 0 0;

                .btn-primary {
                    border-color: #1890ff;
                    background: #1890ff;
                    color: #fff;

                    &:hover {
                        color: #1890ff;
                        border-color: #1890ff;
                        background: #fff;
                    }
                }
            }

        }
    }

    .ant-modal-footer {
        display: none;
    }

}

.speaking-tutor-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

.load-more-btn {
    margin: 0 auto;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 4px 20px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;

    img {
        height: 24px;
    }
}


.ai-tutor-layout.ant-layout-content .ant-form .middle-row.coman-row .right-part.upload-right-part .upload-wrap span.ant-select-arrow {
    position: absolute !important;
}

.ai-tutor-layout.ant-layout-content .ant-form .middle-row.coman-row .right-part .upload-wrap span.ant-select-selection-search {
    position: absolute !important;
}

.ai-tutor-layout.ant-layout-content .ant-form .main-row .ant-form-item .ant-form-item-row .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content .ant-select.folder-type-select span.ant-select-arrow {
    margin-top: -49px;
}



@media screen and (min-device-width: 768px) and (max-device-width: 1199px) {
    .ai-tutor-layout.ant-layout-content .ant-form.content-keyboard {
        margin-bottom: 250px;
    }
}

@media only screen and (max-width: 1440px) {
    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field .cust-one .ant-form-item.select-form-control {
        width: 60%;
    }

    // .ai-tutor-layout.ant-layout-content .ant-form .add-header-field .cust-one .ant-form-item.select-form-control .ant-select.ant-select-in-form-item {
    //     width: 220%;
    // }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field .cust-one {
        width: 75%;
    }
}

@media only screen and (max-width: 1400px) {
    .ai-tutor-layout.ant-layout-content .ant-form .main-row .ant-form-item .ant-form-item-row {
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .main-row.cust-row .ant-form-item .ant-form-item-row .ant-form-item-control {
        width: 100%;
        max-width: 100%;
    }

    // .ai-tutor-layout.ant-layout-content .ant-form .add-header-field .cust-one .ant-form-item.select-form-control .ant-select.ant-select-in-form-item {
    //     width: 275%;
    // }
}

@media only screen and (max-width: 1366px) {
    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input {
        width: 77%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn.action-btn-wrap:after {
        width: 445px;
    }
}

@media only screen and (max-width: 1250px) {

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row.blue-middle-row .left-part,
    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part {
        width: 70%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row.blue-middle-row .left-part .content-list,
    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .content-list {
        width: 35%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row.blue-middle-row .right-part,
    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part {
        width: 30%;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part,
    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part {
        width: 50%;
    }
}

@media only screen and (max-width: 1200px) {

    // .ai-tutor-layout.ant-layout-content .ant-form .main-row {
    //     flex-wrap: wrap;
    // }

    .ai-tutor-layout.ant-layout-content .ant-form .main-row .ant-form-item.select-form-control .ant-form-item-row .ant-form-item-control {
        width: 100%;
        max-width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part.audio-right-part {
        justify-content: flex-start;
        flex-direction: column;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .form-btn .right-btn button.review-save-btn {
        margin-right: 0;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .setting-wrap {
        width: 100%;
        margin-bottom: 20px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .ant-form-item {
        margin-top: 0 !important;
    }

    .ai-tutor-layout.ant-layout-content .ant-form.user-lession .form-btn {
        flex-wrap: wrap;
        gap: 25px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .clear-timer .clear-timer-box {
        top: -13px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn.action-btn-wrap {
        margin: 0;
        gap: 12px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn.action-btn-wrap:after {
        width: 315px;
        height: 30px;
        top: 12px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn {
        gap: 4px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .guide-btn {
        text-align: left;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio img {
        height: 40px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .ant-form-item .ant-row.ant-form-item-row .ant-form-item-label label {
        font-size: 16px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field .cust-one .ant-form-item.select-form-control .ant-select.ant-select-in-form-item {
        width: 93%;
    }

    .ant-layout-has-sider .ai-tutor-layout.ant-layout-content .ant-form .add-header-field .cust-one .ant-form-item.select-form-control .ant-select.ant-select-in-form-item {
        width: 93%;
    }

    // .ant-layout-has-sider .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input .promot-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    //     padding: 6px 14px;
    //     font-size: 14px;
    // }
    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form {
        padding: 0 25px 25px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row {
        padding: 30px 20px;
        gap: 10px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .clear-timer .clear-timer-box {
        right: 0;
    }


}

@media only screen and (max-width: 1099px) {
    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part {
        width: 55%;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part {
        width: 45%;
    }

}

@media screen and (min-device-width: 992px) and (max-device-width: 1099px) {
    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .clear-timer .clear-timer-box {
        position: static;
        justify-content: flex-start;
        margin: 0 0 10px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .form_item_wrap .ant-form-item .ant-row .ant-col .ant-form-item-control-input-content .message-box .chat-detail.ai-chat-detail .msg_content_wrapper .msg_block audio {
        width: 245px;
    }
}

@media only screen and (max-width: 991px) {
    .ai-tutor-layout.ant-layout-content .ant-form .middle-row {
        flex-wrap: wrap;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part,
    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part {
        width: 100% !important;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .setting-wrap .setting-wrap-inner {
        margin-top: -40px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .clear-timer .clear-timer-box {
        top: -2px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row.blue-middle-row .left-part .content-list {
        width: 40%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn.action-btn-wrap {
        gap: 30px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn.action-btn-wrap:after {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn {
        gap: 16px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part {
        margin: 0 0 30px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .form_item_wrap .setting-wrap .setting-wrap-inner {
        margin-top: 0;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .form_item_wrap .setting-wrap {
        margin-bottom: 0;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input .ant-form-item .ant-row .ant-form-item-control {
        width: 100%;
        max-width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .action_icons {
        flex: 100%;
        max-width: 100%;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .content-list {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form {
        padding: 40px 25px;
        // height: 220vh;
    }

    .ai-tutor-layout.ant-layout-content .ant-form:after {
        // height: 218vh;
        left: 5px;
        right: 5px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part {
        flex-wrap: wrap;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .content-list .list-items {
        min-height: 100px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input .promot-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
        font-size: 14px;
        padding: 6px 14px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .ant-form-item .ant-row.ant-form-item-row .ant-form-item-label label {
        font-size: 16px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .form-btn .setting-btns.setting-audio .left-btn img {
        height: auto;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn {
        gap: 10px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn img {
        height: 40px;
    }

    // .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn.action-btn-wrap:after {
    //     width: 375px;
    // }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn.action-btn-wrap {
        gap: 15px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field.dynamic-content .cust-one {
        width: 68%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field.dynamic-content .cust-two {
        width: 30%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row.blue-middle-row .left-part .content-list {
        width: 100%;
    }

    .ai-speaking-main .level-up-library-con .main-inner-wrap.float__box .float__box {
        flex-direction: column;
        padding-bottom: 18px;
    }

    .ai-tutor-layout.ant-layout-content .add-header h2 {
        font-size: 18px;
    }

    .ai-tutor-layout.ant-layout-content .add-header .right-section {
        flex-wrap: wrap;
        justify-content: end;
    }

    .ai-tutor-layout.ant-layout-content .add-header .right-section .upload-btn img {
        width: 80px;
    }

    .ai-tutor-layout.ant-layout-content .add-header .right-section button img {
        width: 15px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap {
        flex-direction: column;
        gap: 30px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field.dynamic-content {
        flex-direction: column;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field.dynamic-content .cust-one {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field.dynamic-content .cust-two {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn.action-btn-wrap {
        gap: 20px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn.action-btn-wrap:after {
        width: 400px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn.action-btn-wrap {
        gap: 8px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row {
        gap: 45px;
    }

    // .ai-tutor-layout.ant-layout-content .ant-form.user-lession {
    //     height: 160vh !important;
    // }

    // .ai-tutor-layout.ant-layout-content .ant-form.user-lession:after {
    //     height: 157vh !important;
    // }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .setting-wrap {
        margin-top: -32px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .guide-btn {
        text-align: center;
    }

    .ai-tutor-layout.ant-layout-content .add-header .right-section button {
        height: 24px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 575px) {
    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input .ant-form-item .ant-row .ant-col.ant-form-item-label {
        margin: 0 0 10px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form {
        padding: 0 15px 30px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row {
        padding: 20px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn.action-btn-wrap {
        height: 30px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn .action_item {
        min-width: 40px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn.action-btn-wrap .action_item .img_wrap {
        padding: 3px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn.action-btn-wrap .action_item .img_wrap img {
        width: 35px;
        height: 38px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn.action-btn-wrap {
        gap: 7px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn .mice_item img {
        width: 45px !important;
        height: 48px !important;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn .action_item h6 {
        display: none;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn .action_item img {
        height: unset;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn {
        gap: 7px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn.leftt .enabled-btn {
        width: 45px;
        height: 24px;
    }
}

@media only screen and (max-width: 520px) {
    .ai-tutor-layout.ant-layout-content .ant-form .main-row {
        flex-wrap: wrap;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .form-btn .setting-btns .left-btn img {
        height: 22px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .form-btn .right-btn img {
        height: 22px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .form-btn .right-btn button.review-save-btn span {
        margin-right: 34px;
        font-size: 16px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field {
        display: flex;
        flex-direction: column;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field .cust-one {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field .cust-two {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .main-row .ant-form-item .ant-form-item-row .ant-select.ant-select-in-form-item {
        width: 100% !important
    }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field .cust-one .ant-form-item.select-form-control {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .add-header-field.dynamic-content .cust-one .ant-form-item.select-form-control {
        width: 100%;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .main-row.cust-row .ant-form-item .ant-form-item-row .ant-form-item-control {
        width: 100%;
        max-width: 100%;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .form_item_wrap {
        flex-direction: column;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .form_item_wrap .setting-wrap .setting-wrap-inner {
        flex-direction: row;
    }
}

@media only screen and (max-width: 479px) {
    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .form_item_wrap .ant-form-item .ant-row .ant-col .ant-form-item-control-input-content .message-box .chat-detail.ai-chat-detail .msg_content_wrapper .msg_block .msg_image {
        width: 175px !important;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .form_item_wrap .ant-form-item .ant-row .ant-col .ant-form-item-control-input-content .message-box .chat-detail.ai-chat-detail .msg_content_wrapper .msg_block audio {
        width: 175px;
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .form_item_wrap .ant-form-item .ant-row .ant-col .ant-form-item-control-input-content .message-box {
        padding: 25px 15px;
    }
}

@media only screen and (max-width: 420px) {
    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .setting-action-wrap .left-btn.action-btn-wrap {
        margin: 0px 100px;
        gap: 10px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .left-part .middle-input.audio-input .setting-btns.setting-audio .left-btn.action-btn-wrap:after {
        width: 320px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .clear-timer .clear-timer-box .item p {
        font-size: 18px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .clear-timer .clear-timer-box {
        gap: 10px;
        top: -2px;
    }

    .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .clear-timer .clear-timer-box .item .clear-btn {
        font-size: 16px;

        img {
            height: 22px;
        }
    }

    .ai-talking-bg-img .ai-tutor-layout.ant-layout-content .ant-form .middle-row .right-part .dialog-wrap .clear-timer .clear-timer-box {
        position: static;
        justify-content: flex-start;
        margin: 0 0 10px;
    }
}

.ant-image-preview-root.msg_image {
    .ant-image-preview-mask {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

.ant-image-preview-operations-wrapper.msg_image {
    .ant-image-preview-operations-icon {
        font-size: 40px;
    }
}